<template>
  <div class="wrap">
    <div class="nav">
      <div class="navList">
        <div @click="jump('1')">
          <span>首页</span>
          <span>Home</span>
        </div>
        <div @click="jump('2')" class="cur">
          <span>服务案例</span>
          <span>Case</span>
        </div>
        <div @click="jump('3')">
          <span>新闻资讯</span>
          <span>News</span>
        </div>
        <div @click="jump('4')">
          <span>业务伙伴</span>
          <span>Cooperation</span>
        </div>
        <div @click="jump('5')">
          <span>关于我们</span>
          <span>About</span>
        </div>
      </div>
    </div>
    <div class="navItem">
      <img src="../../public/imgs/caseNew/CASE.png" alt="" />
      <ul class="workNav">
        <li
          v-for="(x, index) in workList"
          :key="x"
          :class="{ navMainBorder: index == n }"
          @click="changeList(index)"
        >
          {{ x }}
        </li>
      </ul>
    </div>
    <div class="case">
      <div v-for="(x, index) in list" :key="index" @click="jumpCase(x.id)">
        <img :src="x.img" alt="" />
        <div class="advantageRightText">
          <p class="twisterP1Class" style="font-weight: 600; font-size: 20px">
            {{ x.title }}
          </p>
          <p class="twisterP2Class">地址：{{ x.address }} 面积：{{ x.area }}</p>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../components/Footer";
import JRcase1 from "../../public/imgs/caseNew/JRcase1.png";
import JRcase2 from "../../public/imgs/caseNew/JRcase2.png";
import JRcase3 from "../../public/imgs/caseNew/JRcase3.png";
import JRcase4 from "../../public/imgs/caseNew/JRcase4.png";
import JRcase5 from "../../public/imgs/caseNew/JRcase5.png";
import JRcase6 from "../../public/imgs/caseNew/JRcase6.png";
import JRcase7 from "../../public/imgs/caseNew/JRcase7.png";
import BScase1 from "../../public/imgs/caseNew/BScase1.png";
import YLcase1 from "../../public/imgs/caseNew/YLcase1.png";
import ZHcase1 from "../../public/imgs/caseNew/ZHcase1.png";
import ZHcase2 from "../../public/imgs/caseNew/ZHcase2.png";
import ZHcase3 from "../../public/imgs/caseNew/ZHcase3.png";
import XXcase1 from "../../public/imgs/caseNew/XXcase1.png";

export default {
  name: "index",
  components: {
    Footer,
  },
  data() {
    return {
      workList: [
        "金融办公",
        "地产项目",
        "商业娱乐",
        // "医疗教育",
        // "休闲运动",
        // "展览展会",
      ],
      list: [],
      // 金融
      JRlist: [
        {
          img: JRcase2,
          title: "兴业银行西安分行",
          address: "西安市雁塔区唐延路1号",
          area: "2000㎡",
          id: "5",
        },
        {
          img: JRcase4,
          title: "兴业银行榆林分行",
          address: "陕西榆林市经济开发区明珠大道世纪精华大厦",
          area: "2500㎡",
          id: "7",
        },
        {
          img: JRcase3,
          title: "兴业银行汉中分行",
          address: "陕西省汉中市汉台区华汉新世纪商城",
          area: "2000㎡",
          id: "6",
        },
        {
          img: JRcase7,
          title: "兴业银行西长安街支行",
          address: "西安市长安区西长安街288号1期",
          area: "2000㎡",
          id: "15",
        },
        {
          img: JRcase1,
          title: "广发银行榆林分行",
          address: "榆林市高新技术产业园区盛高时代",
          area: "1805㎡",
          id: "4",
        },
        {
          img: JRcase5,
          title: "建设银行西影路支行",
          address: "西安市西影路105号水木兰亭小区1幢1单元10102室",
          area: "255㎡",
          id: "14",
        },
        {
          img: JRcase6,
          title: "招商银行锦业二路自助银行",
          address: "西安市长安区锦业二路法士特赛斐广场",
          area: "--",
          id: "13",
        },
        // {
        //   img: JRcase1,
        //   title: "建设银行（西影路东段支行）",
        //   address: "西安市西影路105号",
        //   area: "面积：255㎡",
        //   id: "4",
        // },
      ],
      // 别墅
      BSlist: [
        {
          img: BScase1,
          title: "盛世长安三期",
          address: "西安市长安区郭杜盛世长安",
          area: "15000㎡",
          id: "9",
        },
        {
          img: ZHcase1,
          title: "i 尚高新·七星城",
          address: "西安市雁塔区",
          area: "188587.35㎡",
          id: "10",
        },
      ],
      // 商业综合体
      SYlist: [
        {
          img: ZHcase2,
          title: "东方国际广场",
          address: "西安康复路商圈长乐西路中段",
          area: "18000㎡",
          id: "3",
        },
        {
          img: ZHcase3,
          title: "雍顺兴·臭鳜鱼·秦徽小筑",
          address: "西安市高新区89号枫林绿洲云顶园7号楼",
          area: "432㎡",
          id: "2",
        },
      ],
      // 医疗教育
      YLlist: [
        {
          img: YLcase1,
          title: "宝鸡汉强幼儿园",
          address: "陕西省宝鸡市",
          area: "",
          id: "1",
        },
      ],
      // 休闲运动
      XLlist: [
        {
          img: XXcase1,
          title: "明德门足浴店",
          address: "西安市明德门",
          area: "1250㎡",
          id: "8",
        },
      ],
      // 展览
      ZLlist: [],
      n: 0,
    };
  },
  mounted() {
    this.list = this.JRlist;
  },
  methods: {
    jumpCase(index) {
      this.$router.push({
        path: "/caseItem",
        query: {
          id: index,
        },
      });
    },
    changeList(index) {
      this.n = index;
      if (index == 0) {
        this.list = this.JRlist;
      }
      if (index == 1) {
        this.list = this.BSlist;
      }
      if (index == 2) {
        this.list = this.SYlist;
      }
      if (index == 3) {
        this.list = this.YLlist;
      }
      if (index == 4) {
        this.list = this.XLlist;
      }
      if (index == 5) {
        this.list = this.ZLlist;
      }
    },
    jump(index) {
      if (index == 1) {
        this.$router.push({
          path: "/",
        });
      }
      if (index == 2) {
        this.$router.push({
          path: "/case",
        });
      }
      if (index == 3) {
        this.$router.push({
          path: "/newsInfo",
        });
      }
      if (index == 4) {
        this.$router.push({
          path: "/partner",
        });
      }
      if (index == 5) {
        this.$router.push({
          path: "/me",
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.wrap {
  height: 100vh;
  width: 1920px;
  margin: 0 auto;
}
.cur {
  color: #028ccd !important;
}
.nav .navList {
  position: absolute;
  top: 25px;
  right: 300px;
  height: 50px;
}
/* 导航 */
.nav {
  width: 100%;
  height: 100px;
  background: #fff url("../../public/imgs/indexNew/logo.png") no-repeat 76px
    center;
  overflow: hidden;
  cursor: pointer;
  position: fixed;
  top: 0;
  z-index: 8888;
}
.navList span {
  display: block;
}
.navList div {
  text-align: center;
  float: left;
  line-height: 50px;
  height: 70px;
  width: 70px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  position: relative;
  top: 0;
  transition: top 0.5s;
}
.navList div span:nth-of-type(1) {
  opacity: 1;
  transition: all 0.5s;
}
.navList div span:nth-of-type(2) {
  opacity: 0;
  transition: all 0.5s;
}
.navList div:hover {
  top: -50px;
}
.navList div:hover span:nth-of-type(1) {
  opacity: 0;
}
.navList div:hover span:nth-of-type(2) {
  opacity: 1;
}
/* 导航 */
.nav li {
  float: left;
  line-height: 100px;
  margin-right: 45px;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}
.case {
  width: 1902px;
  height: 950px;
  margin: 0 auto;
}
.caseIMG {
  width: 100%;
  height: 100%;
}
.advantageRightText {
  width: 100%;
  height: 70px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0px;
  font-size: 14px;
  color: #000;
  transition: all 0.4s;
}
.case > div:hover .advantageRightText {
  height: 100%;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.case > div:hover p:nth-of-type(1) {
  animation: twisterP1 0.4s forwards;
}
.case > div:hover p:nth-of-type(2) {
  animation: twisterP2 0.4s forwards;
}
@keyframes twisterP1 {
  0% {
    font-size: 20px;
  }
  100% {
    font-size: 26px;
  }
}
@keyframes twisterP2 {
  0% {
    margin-top: 10px;
    padding: 0 20px;
  }
  100% {
    margin-top: 280px;
    padding: 0 30px;
  }
}
.advantageRightText p {
  padding: 0 20px;
  margin-top: 10px;
  box-sizing: border-box;
}
.case > div {
  margin-bottom: 47px;
  width: 470px;
  height: 450px;
  float: left;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.case img {
  width: 100%;
  height: 100%;
}
.case img:hover {
  cursor: pointer;
}
.case > div:nth-of-type(1),
.case > div:nth-of-type(2),
.case > div:nth-of-type(3),
.case > div:nth-of-type(5),
.case > div:nth-of-type(6),
.case > div:nth-of-type(7),
.case > div:nth-of-type(9),
.case > div:nth-of-type(10),
.case > div:nth-of-type(11) {
  margin-right: 7px;
}
.case > div:hover .twisterP1Class {
  animation: twisterP1 1s;
}
.case > div:hover .twisterP2Class {
  animation: twisterP2 1s;
}
.case > div:hover img {
  cursor: pointer;
}
@keyframes twisterP1 {
  0% {
    font-size: 14px;
  }
  100% {
    font-size: 26px;
  }
}
@keyframes twisterP2 {
  0% {
    margin-top: 10px;
    padding: 0 20px;
  }
  100% {
    margin-top: 350px;
    padding: 0 30px;
  }
}

.navItem {
  position: relative;
  height: 50px;
  width: 100%;
  background: #565656;
  margin-bottom: 10px;
  margin-top: 120px;
}
.navItem img {
  position: absolute;
  top: -14px;
  left: 300px;
}
.workNav {
  height: 50px;
  margin: 0;
  position: absolute;
  top: 6px;
  right: 200px;
}
.workNav li {
  float: left;
  margin-right: 23px;
  font-size: 14px;
  cursor: pointer;
  color: #fff;
  padding: 10px 0;
}
.navMainBorder {
  border-bottom: 1px solid #fff;
}

.line {
  width: 1200px;
  height: 1px;
  margin: 50px auto;
  background: #ccc;
}
</style>
